import React from 'react';
import Layout from '../components/layout'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight, connectSearchBox } from 'react-instantsearch-dom';
import Image from "gatsby-image"
import { Link } from "gatsby"
import parse from "html-react-parser"

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);

const VirtualSB = connectSearchBox(() => <span />);

export default class Search extends React.Component {
  state = {
    searchState: {
      query: '',
    },
  };

  componentDidMount() {    
    let term = this.props.location.search
    if(term) {
      term = term.split("=")
      if(term[1]) {
        term = term[1] 
      } else {
        term = ''
      }        
    }
    this.setState(state => ({
      searchState: {
        ...state.searchState,
        query: term,
      },
    }));
  }

  render() {
    if (this.state.searchState.query == '') return ''
      return (      
          <Layout>      
              <div className="outer">
                  <div className="inner" >
                      <InstantSearch  searchClient={searchClient} indexName="Posts" searchState={this.state.searchState}>
                        <VirtualSB />
                        <Hits hitComponent={Hit} />
                      </InstantSearch>
                  </div>
              </div>    
          </Layout>        
      );
  }
}

function Hit(props) {
  console.log(props)
  let featuredImage = {
    fluid: props.hit.node?.localFile?.childImageSharp?.fluid,
    alt: props.hit.node?.alt || ``,
  }
    return (
        
              <article className="card card-horizontal card-hover mb-grid-gutter" itemScope
                itemType="http://schema.org/Article">
              <div className="card-img-top d-flex align-items-center justify-content-center">
                                
                    

                {featuredImage?.fluid && (
                    <Image
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                      style={{  width: '100%' }}
                    />
                  )}
              </div>
              
              <div class="card-body">
                <h2 class="h4 nav-heading text-capitalize mb-3">
                    <Link to={props.hit.uri} itemProp="url">
                      <span itemProp="headline">{parse(props.hit.title)}</span>
                    </Link>
                </h2>
                
                <section itemProp="description" class="mb-0 font-size-lg ">                  
                  {parse(props.hit.excerpt)}
                </section>
                
                <div class="mt-3 text-right text-nowrap"></div>
              </div>
              </article>                                              
              
                
    );
  }